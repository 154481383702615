@use 'sass:color'
@use 'variables' as *
@use 'colors'
@use 'grid'
@use 'arrows'
@use 'bpmn'
@use 'loader'
@use 'table'
@use 'framework'
@use 'print_page'
@use 'card_save_as'

html, body
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

body
    background: $satys-light-accent
#body
    padding-top: 1px
    margin-top: -1px
    display: flex

    > *
        flex-basis: 100%
a
    text-decoration: none
hr
    border-style: solid
    border-color: #CCCCCC
    border-width: 1px
p, a, i, b, strong, h1, h2, h3, h4, h5, h6
    &.f-inherit
        font-size: inherit
        line-height: inherit

.z--1
    z-index: -1

.flex-1
  flex: 1

.pre-wrap
    white-space: pre-wrap

.v-fill-screen
    min-height: calc(100vh - var(--header-height))

.measure-narrow,
.measure,
.measure-wide,
.measure-broad
    margin-left: auto
    margin-right: auto

.measure-broad
    width: 95%
    max-width: 96rem !important

.page
  position: relative
  display: flex
  flex-direction: column
  flex: 1

.nested-svg > svg
    width: 100%
    max-width: 100%
    display: block

.skeleton
    .skel
        animation: slide 1s infinite
        background-repeat: repeat
        background-size: 200% 100%
        background-image: linear-gradient(90deg, rgb(210,210,210) 80%, rgb(240,240,240) 90%, rgb(210,210,210) 100%)

    &:nth-child(3n+1) .skel,
    .skel:nth-child(3n+1)
        animation-delay: 100ms
    &:nth-child(3n+2) .skel,
    .skel:nth-child(3n+2)
        animation-delay: 60ms
    &:nth-child(3n+3) .skel,
    .skel:nth-child(3n+3)
        animation-delay: 20ms

i.before
    font-size: 1rem
    vertical-align: top
    margin-right: .5rem

.no-select
    user-select: none

.capitalize
  text-transform: capitalize

.noshadow
    box-shadow: none !important

.small-action
    height: 2.5rem
    width: 2.5rem
    text-align: center
    padding: 0px
    background: white
    color: $satys-accent
    box-shadow: 0px 4px 6px 0px color.adjust($satys-dark-accent, $alpha: -0.8)
    border-radius: 100%
    transition: .15s ease

    &:hover, &.active
        background: $satys-accent
        color: white
    &:active
        box-shadow: inset 0px 4px 6px 0px color.adjust($satys-dark-accent, $alpha: -0.8)
    i
        line-height: 2.5rem
        font-size: 1.5rem

.pointer-events-none
  pointer-events: none

.fade-in
    animation: fadeIn 0.5s

.tsparticles canvas
  border-radius: .5rem
  overflow: hidden

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@include large
  .sticky-top
    position: sticky
    top: calc(4rem - 12px)
    z-index: 1
    border: none
    padding: 0.5rem 1.5rem
    filter: drop-shadow(0px 0.3rem 0.15rem rgba(0, 0, 0, 0.05))

@media print
  body, html
    font-size: 12px
    background-color: #FFFFFF
    color: #000000 !important

  #body
    display: block

  .noprint
    display: none

  table, tr, td, th, tbody, thead, tfoot
    page-break-inside: avoid !important

  .daterange span
    display: none

  section:not(:first-child)
    page-break-before: always
    break-before: always

  .titleseparator
    page-break-after: avoid
    break-after: avoid
    margin-bottom: 2rem !important

    .spacing
      display: none !important

    hr
      display: none

  svg
    height: auto !important

  .grid > div
    &:not(.type-analyses):not(.type-open_question)
      break-inside: avoid

  .tooltip.kpi-label
    display: none

  .type-analyses
    .navigation
      display: none !important

    .analyses-accordion .analyses
      display: initial
      flex-direction: column
      height: auto

      .analysis
        flex-basis: unset !important
        padding: 1rem 0
        widows: 2
        orphans: 2
        break-inside: avoid !important

  .type-open_question .table-graph .graphgrid
    max-height: unset

    .row .column.date
      opacity: 1

  .type-choice_question .flex
    flex-direction: column
    > div
      flex-shrink: 1
      padding: 0 1rem 0 3rem
    > div:not(:last-child)
      margin-bottom: 2rem

  .noprint, .expand
    display: none !important

  .card-grid .col-center-8
    grid-column-start: 1
    grid-column-end: 12

  .pe-icon.pe-icon--regular.dropdown,
  .card-grid form,
  .answer-filter,
  .daterange .pe-button:not(.pe-button--selected)
    display: none

  .card
    filter: none
    border: 2px solid $light-grey

    &.type-image img
      width: 100%

  .card
    box-decoration-break: clone

    .card-info
      display: none

    .card-grid
      display: initial

      .card-tabs
        display: none

  .labels, .overlays, .responses-labels, .answer-labels
    opacity: 1 !important

  .graphcontainer .overlays,
  .graphcontainer .labels
      font-size: 16px

  .graphcontainer .tooltip
    display: none

  @page
    margin: 2rem 0

  @page :first
    margin: 0 0 2rem 0

// Try to print the colors as they appear on screen
@media print and (color)
   *
      -webkit-print-color-adjust: exact
      print-color-adjust: exact


// Skeleton slide
@keyframes slide
    0%
      background-position-x: 0%
    100%
      background-position-x: -133%
