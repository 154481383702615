@use 'variables' as *
@use 'sass:color'

.table-view

  &.edit table > tr:after
    content: 'edit'
    font-size: 1.5rem
    position: relative
    top: 6px

  tr.no-edit:after
    content: '' !important
    width: 1.5rem

  table
    width: 100%

  table > tr
    display: flex
    gap: 1rem
    padding: 8px
    border-bottom: solid 1px lightgrey
    position: relative
    min-height: 42px

    &:first-child, &:last-child
      border-bottom: none

    &:hover:not(:first-child)
      background-color: $satys-accent
      color: white
      cursor: pointer

    &:first-child:after
      opacity: 0

    *
      flex: 1

    th:first-child
      text-align: left

    td:not(:first-child)
      text-align: center

    td, th
      display: flex
      align-items: center

      > div
        margin: auto

  .table-active-indicator
    position: relative
    display: block
    width: 10px
    height: 10px
    border-radius: 50%
    margin: 10px
    transform: scale(1)
    cursor: pointer

    &.pulse
      background: $satys-light-green
      box-shadow: 0 0 0 0 $satys-light-green
      animation: pulse-green 2s infinite
      transition: all .2s ease

  @keyframes pulse-green
    0%
      transform: scale(0.95)
      box-shadow: 0 0 0 0 rgba($satys-light-green, 0.7)

    70%
      transform: scale(1)
      box-shadow: 0 0 0 10px rgba($satys-light-green, 0)

    100%
      transform: scale(0.95)
      box-shadow: 0 0 0 0 rgba($satys-light-green, 0)

