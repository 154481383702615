@use 'variables' as *
@use 'sass:color'
@use 'sass:math'

.grid
    display: grid
    grid-gap: 2rem
    grid-template-columns: repeat(12, minmax(10px, 1fr))
    grid-template-rows: auto
    margin-bottom: 3rem

    @include not-large
        > .card
            grid-column: span 12 !important

.card-grid
    display: grid
    grid-gap: .5rem 1rem
    grid-template-columns: repeat(12, 1fr)
    grid-template-rows: auto

    > div
        max-width: 100%

    > div > span.expand
        text-align: center
        display: block
        position: relative
        user-select: none
        cursor: pointer

        &:not(.expanding):after
            content: "expand_more"
            font-family: "Material Icons"
            font-weight: bold
            font-size: 1.5rem
            position: absolute
            top: -.25rem

        &.expanded:after
            content: "expand_less"

        &.expanding
            opacity: .6
            cursor: progress

.filters
    @include not-large
        flex-wrap: wrap

        > div
            width: auto !important
            margin-bottom: 1rem

.button-card
    overflow-x: hidden

.card
    position: relative
    padding: .5rem 1.5rem 1rem
    padding: .5rem 2rem
    background: white
    border: 1px solid $light-grey
    border-radius: .5rem
    filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.10))
    will-change: filter // To get safari to show the drop-shadow
    height: fit-content
    color: #555
    &[class*="_question"] h3.title
      margin-right: calc(25% + 32px)

    &--row
        flex-direction: row

    .title-icon
        position: absolute
        top: 2rem
        left: 2rem

    .title-icon + .title
      margin-left: 2.5rem

.type-choice_question
    .flex
        align-items: justify-between

        > * ul
            list-style: none
            padding: 0 2rem
            margin: 0
            margin-bottom: 2rem

            &:last-child
                margin-bottom: 0

    .cohort-score
        ul.cohorts
            li
                display: flex
                flex-direction: row
                border-bottom: solid 1px $light-grey
                padding: .5rem 0

                &:last-child
                    border: none

                > .score
                    margin-left: auto
                    margin-right: .5rem

    ul:not(.cohorts)
        text-align: center

        li
            display: flex
            flex-direction: column

            > h2
                font-size: 1.5rem
                font-weight: 300
                margin: 0px

.button-card
    display: flex
    padding-right: 0
    transition: .3s ease
    cursor: pointer

    *
        user-select: none

    .icon
        display: flex
        flex-direction: column
        margin: auto 1rem

        i
            font-size: 4.5rem

    .content
        margin: 0
        margin-left: 1rem
        margin-right: auto

    .arrow
        display: flex
        margin: auto
        position: relative
        margin-right: 2rem

        i
            font-size: 3.5rem
            transition: .3s ease

    &:hover
        border-color: $satys-accent

        .arrow i
            color: $satys-accent

.card-tabs .pe-tab.pe-text-button.pe-button .pe-button__content .pe-button__label
    font-weight: normal
    font-size: .67rem

@for $i from 1 through 12
    .col-span-#{$i}
        grid-column: span $i

    .row-span-#{$i}
        grid-row: span $i

    .col-center-#{$i}
        grid-column-start: math.div((12 - $i), 2) + 1
        grid-column-end: 12 - math.div((12 - $i), 2) + 1

@keyframes jump
    from
        transform: translate(0, 0)
    25%
        transform: translate(0, -6px)
    50%
        transform: translate(0, 0)

.jumping-ellipsis
    display: inline-block
    padding-left: .25rem

    > div
        animation: jump
        display: inline-block
        animation-duration: 1.2s
        animation-iteration-count: infinite

        @for $i from 1 through 3
            &:nth-child(#{$i})
                animation-delay: .125s * $i

:root
    --sentiment-start: #{$satys-light-red}
    --sentiment-end: #{$satys-light-purple}
    --sentiment-speed: 4s

.sentiment-header
    animation-direction: alternate
    animation-name: sentiment-breathing
    animation-duration: var(--sentiment-speed)
    animation-fill-mode: both
    animation-iteration-count: infinite
    animation-timing-function: ease-in-out

@media print
    .grid
        display: block

        > .card
            margin-bottom: 2rem

    .sentiment-header
        background-image: linear-gradient(to right, $satys-purple, $satys-red)

@keyframes sentiment-breathing
    0%
        background: var(--sentiment-start)
    50%
        background: var(--sentiment-end)
    100%
        background: var(--sentiment-start)
