.WK5oAW_bumper {
  z-index: 9998;
}

.WK5oAW_absolute-bumper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.breadcrumbs_599f7c {
  overflow: visible;
}

.breadcrumbs_599f7c .crumbs {
  white-space: nowrap;
  color: #bababa;
  align-items: center;
  display: flex;
}

.breadcrumbs_599f7c .crumbs .crumb-item {
  cursor: pointer;
  color: #bababa;
  padding: 0 5px;
  font-size: 1rem;
  line-height: 1.5rem;
}

.breadcrumbs_599f7c .crumbs .crumb-item--margin-top {
  margin-top: 3px;
}

.breadcrumbs_599f7c .crumbs .crumb-item svg {
  width: .8rem;
}

.breadcrumbs_599f7c .crumbs .parent {
  max-width: 150px;
  text-overflow: ellipsis;
  color: #bababa;
  display: inline-block;
  overflow: hidden;
}

.breadcrumbs_599f7c .crumbs .parent:active, .breadcrumbs_599f7c .crumbs .parent:focus, .breadcrumbs_599f7c .crumbs .parent:hover {
  color: #bababa;
  max-width: none;
  text-decoration: underline;
  display: inline;
}

.breadcrumbs_599f7c .crumbs .seperator {
  margin-top: 1px;
  font-size: 1rem;
  line-height: 1rem;
}

.breadcrumbs_599f7c .crumbs .current .crumb-item {
  color: #505050;
  font-weight: bold;
}

.breadcrumbs_599f7c .crumbs .dropdown {
  z-index: 10;
  min-width: 200px;
  max-height: 80vh;
  border: 1px solid var(--light-grey);
  background-color: #fff;
  border-radius: 5px;
  display: none;
  position: absolute;
  top: 150%;
  left: calc(5px - 1rem);
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 8px 15px -2px #0000001a;
}

.breadcrumbs_599f7c .crumbs .dropdown.active {
  display: block;
}

.breadcrumbs_599f7c .crumbs .dropdown-item {
  cursor: pointer;
  color: #bababa;
  padding: 1rem;
  font-weight: bold;
  display: block;
}

.breadcrumbs_599f7c .crumbs .dropdown-item:hover, .breadcrumbs_599f7c .crumbs .dropdown-item.active {
  background-color: var(--satys-light-accent);
  color: #505050;
}

@media print {
  .bread-crumbs_599f7c {
    font-size: 20px;
  }

  .bread-crumbs_599f7c .crumbs a {
    max-width: none;
    text-decoration: none;
    display: inline;
  }

  .bread-crumbs_599f7c .crumbs span:nth-child(1) {
    display: none;
  }

  .bread-crumbs_599f7c .crumbs span:not(:last-child) {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.wrapper_50f720 {
  width: 100%;
  height: 3rem;
  filter: drop-shadow(0 0 .3rem #0000001a);
  will-change: filter;
  z-index: 200;
  position: sticky;
  top: 0;
}

.wrapper_50f720 #header {
  width: 100%;
  height: 3rem;
  z-index: 9999;
  border-bottom: 1px solid var(--satys-bg-color);
  background: #fff;
  align-items: center;
  padding: .5rem;
  display: flex;
  position: sticky;
  top: 0;
}

.wrapper_50f720 #header a.give_feedback {
  color: var(--var-text-color);
  color: #ba2e6d;
  text-decoration: none;
}

.wrapper_50f720 #header a.give_feedback:hover .emoji {
  opacity: 1;
}

.wrapper_50f720 #header a.give_feedback .emoji {
  opacity: 0;
  margin-right: 10px;
  transition: opacity .3s;
}

@media print {
  #header_50f720 {
    position: absolute !important;
  }
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.sidebar_wrapper_2c40f9 {
  width: 100%;
  max-width: 18rem;
}

.sidebar_wrapper_2c40f9 .sidebar {
  height: 100vh;
  width: 100%;
  border-right: 1px solid var(--satys-bg-color);
  filter: drop-shadow(0 0 .3rem #0000001a);
  will-change: filter;
  z-index: 201;
  background-color: #fff;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
}

.sidebar_wrapper_2c40f9 .sidebar__brand {
  align-items: center;
  justify-content: between;
  padding: 2rem;
  display: flex;
}

.sidebar_wrapper_2c40f9 .sidebar__image {
  height: auto;
  width: 8.5rem;
  margin: .5rem;
}

.sidebar_wrapper_2c40f9 .sidebar__link {
  cursor: pointer;
  color: #555;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
}

.sidebar_wrapper_2c40f9 .sidebar__link svg {
  width: 20px;
  margin-right: 15px;
}

.sidebar_wrapper_2c40f9 .sidebar__link > span {
  align-items: center;
  padding: 1rem;
  display: flex;
}

.sidebar_wrapper_2c40f9 .sidebar__link:after {
  height: 100%;
  width: 2px;
  content: "";
  background: none;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar_wrapper_2c40f9 .sidebar__link.active, .sidebar_wrapper_2c40f9 .sidebar__link:hover {
  color: var(--satys-text-color);
  background-color: var(--satys-bg-color);
  border-radius: 5px;
}

.sidebar_wrapper_2c40f9 .sidebar__link.active svg, .sidebar_wrapper_2c40f9 .sidebar__link:hover svg {
  color: var(--satys-accent);
}

.sidebar_wrapper_2c40f9 .sidebar__link.active:after, .sidebar_wrapper_2c40f9 .sidebar__link:hover:after {
  height: -100%;
  width: 2px;
  content: "";
  background-color: var(--satys-accent);
  position: absolute;
  top: 0;
  left: -1rem;
}

.sidebar_wrapper_2c40f9 .spacer {
  height: 2px;
  background-color: var(--satys-bg-color);
  margin: 2rem 0;
}

.sidebar_wrapper_2c40f9 .sidebar__footer {
  width: 100%;
  max-width: 100%;
  padding: .5rem;
  position: relative;
}

.sidebar_wrapper_2c40f9 .sidebar__footer-item {
  color: var(--satys-text-color);
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  display: flex;
}

.sidebar_wrapper_2c40f9 .sidebar__footer-item:hover {
  background-color: var(--satys-bg-color);
}

.sidebar_wrapper_2c40f9 .sidebar__footer-item svg {
  width: 1rem;
  color: #aeaeae;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile {
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile .details {
  max-width: 100%;
  width: 100%;
  grid-template-columns: 1fr 40px;
  align-items: center;
  display: grid;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile > span {
  color: #ba2e6d;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile .avatar {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--var-text-color-light);
  color: var(--var-text-color-light);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile .user, .sidebar_wrapper_2c40f9 .sidebar__footer .profile .role {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile .user {
  color: #ba2e6d;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile .role {
  color: silver;
  font-size: .875rem;
  font-style: italic;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile .logout {
  height: 40px;
  width: 40px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar_wrapper_2c40f9 .sidebar__footer .profile .logout svg {
  width: 1.5rem;
  color: #aeaeae;
}

@media screen and (max-width: 768px) {
  .sidebar_2c40f9 {
    max-width: 14rem;
  }
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

#profile .tabs {
  flex-grow: 0;
}

#profile .tabs.tabshadow {
  box-shadow: 2px 0 8px -3px #0003;
}

#profile .tabs .pe-list-tile.active .pe-list-tile__primary {
  color: #ba2e6d;
  border-right: 2px solid #ba2e6d;
  font-weight: bold;
}

#profile .tabs .pe-list-tile .pe-list-tile__primary {
  color: #0006;
  text-align: right;
  border-right: 2px solid #0000;
}

#profile .tabs .pe-list-tile .pe-list-tile__primary:hover, #profile .tabs .pe-list-tile .pe-list-tile__primary:focus, #profile .tabs .pe-list-tile .pe-list-tile__primary:active {
  color: #ba2e6d;
  border-right: 2px solid #ba2e6d;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.satys-accent {
  color: #ba2e6d;
}

.bg-satys-accent {
  background-color: #ba2e6d;
}

.b--satys-accent {
  border-color: #ba2e6d;
}

.hover-satys-accent:hover, .hover-satys-accent:focus {
  color: #ba2e6d;
}

.hover-bg-satys-accent:hover, .hover-bg-satys-accent:focus {
  background-color: #ba2e6d;
}

.satys-dark-accent {
  color: #3f040c;
}

.bg-satys-dark-accent {
  background-color: #3f040c;
}

.b--satys-dark-accent {
  border-color: #3f040c;
}

.hover-satys-dark-accent:hover, .hover-satys-dark-accent:focus {
  color: #3f040c;
}

.hover-bg-satys-dark-accent:hover, .hover-bg-satys-dark-accent:focus {
  background-color: #3f040c;
}

.satys-light-accent {
  color: #fbf8fd;
}

.bg-satys-light-accent {
  background-color: #fbf8fd;
}

.b--satys-light-accent {
  border-color: #fbf8fd;
}

.hover-satys-light-accent:hover, .hover-satys-light-accent:focus {
  color: #fbf8fd;
}

.hover-bg-satys-light-accent:hover, .hover-bg-satys-light-accent:focus {
  background-color: #fbf8fd;
}

.bg-satys-gradient {
  background-image: linear-gradient(to right, #833ab4, #fd1d1d);
}

.bg-satys-light-gradient {
  background-image: linear-gradient(to right, #c980e1, #eb6e6e);
}

.background-with-slide {
  cursor: default;
  background-image: linear-gradient(to left, #ba2e6d 50%, #fff 50%);
  background-size: 200% 100%;
  transition: all .25s;
}

.background-with-slide:hover, .background-with-slide:focus {
  color: #fff;
  background-position: -100% 0;
  border-color: #833ab4;
}

.grid {
  grid-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  margin-bottom: 3rem;
  display: grid;
}

@media (max-width: 70em) {
  .grid > .card {
    grid-column: span 12 !important;
  }
}

.card-grid {
  grid-gap: .5rem 1rem;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
}

.card-grid > div {
  max-width: 100%;
}

.card-grid > div > span.expand {
  text-align: center;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
}

.card-grid > div > span.expand:not(.expanding):after {
  content: "expand_more";
  font-family: Material Icons;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  top: -.25rem;
}

.card-grid > div > span.expand.expanded:after {
  content: "expand_less";
}

.card-grid > div > span.expand.expanding {
  opacity: .6;
  cursor: progress;
}

@media (max-width: 70em) {
  .filters {
    flex-wrap: wrap;
  }

  .filters > div {
    margin-bottom: 1rem;
    width: auto !important;
  }
}

.button-card {
  overflow-x: hidden;
}

.card {
  filter: drop-shadow(0 0 .3rem #0000001a);
  will-change: filter;
  height: -moz-fit-content;
  height: fit-content;
  color: #555;
  background: #fff;
  border: 1px solid #eee;
  border-radius: .5rem;
  padding: .5rem 2rem;
  position: relative;
}

.card[class*="_question"] h3.title {
  margin-right: calc(25% + 32px);
}

.card--row {
  flex-direction: row;
}

.card .title-icon {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.card .title-icon + .title {
  margin-left: 2.5rem;
}

.type-choice_question .flex {
  align-items: justify-between;
}

.type-choice_question .flex > * ul {
  margin: 0 0 2rem;
  padding: 0 2rem;
  list-style: none;
}

.type-choice_question .flex > * ul:last-child {
  margin-bottom: 0;
}

.type-choice_question .cohort-score ul.cohorts li {
  border-bottom: 1px solid #eee;
  flex-direction: row;
  padding: .5rem 0;
  display: flex;
}

.type-choice_question .cohort-score ul.cohorts li:last-child {
  border: none;
}

.type-choice_question .cohort-score ul.cohorts li > .score {
  margin-left: auto;
  margin-right: .5rem;
}

.type-choice_question ul:not(.cohorts) {
  text-align: center;
}

.type-choice_question ul:not(.cohorts) li {
  flex-direction: column;
  display: flex;
}

.type-choice_question ul:not(.cohorts) li > h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 300;
}

.button-card {
  cursor: pointer;
  padding-right: 0;
  transition: all .3s;
  display: flex;
}

.button-card * {
  user-select: none;
}

.button-card .icon {
  flex-direction: column;
  margin: auto 1rem;
  display: flex;
}

.button-card .icon i {
  font-size: 4.5rem;
}

.button-card .content {
  margin: 0 auto 0 1rem;
}

.button-card .arrow {
  margin: auto 2rem auto auto;
  display: flex;
  position: relative;
}

.button-card .arrow i {
  font-size: 3.5rem;
  transition: all .3s;
}

.button-card:hover {
  border-color: #ba2e6d;
}

.button-card:hover .arrow i {
  color: #ba2e6d;
}

.card-tabs .pe-tab.pe-text-button.pe-button .pe-button__content .pe-button__label {
  font-size: .67rem;
  font-weight: normal;
}

.col-span-1 {
  grid-column: span 1;
}

.row-span-1 {
  grid-row: span 1;
}

.col-center-1 {
  grid-column-start: 6.5;
  grid-column-end: 7.5;
}

.col-span-2 {
  grid-column: span 2;
}

.row-span-2 {
  grid-row: span 2;
}

.col-center-2 {
  grid-column: 6 / 8;
}

.col-span-3 {
  grid-column: span 3;
}

.row-span-3 {
  grid-row: span 3;
}

.col-center-3 {
  grid-column-start: 5.5;
  grid-column-end: 8.5;
}

.col-span-4 {
  grid-column: span 4;
}

.row-span-4 {
  grid-row: span 4;
}

.col-center-4 {
  grid-column: 5 / 9;
}

.col-span-5 {
  grid-column: span 5;
}

.row-span-5 {
  grid-row: span 5;
}

.col-center-5 {
  grid-column-start: 4.5;
  grid-column-end: 9.5;
}

.col-span-6 {
  grid-column: span 6;
}

.row-span-6 {
  grid-row: span 6;
}

.col-center-6 {
  grid-column: 4 / 10;
}

.col-span-7 {
  grid-column: span 7;
}

.row-span-7 {
  grid-row: span 7;
}

.col-center-7 {
  grid-column-start: 3.5;
  grid-column-end: 10.5;
}

.col-span-8 {
  grid-column: span 8;
}

.row-span-8 {
  grid-row: span 8;
}

.col-center-8 {
  grid-column: 3 / 11;
}

.col-span-9 {
  grid-column: span 9;
}

.row-span-9 {
  grid-row: span 9;
}

.col-center-9 {
  grid-column-start: 2.5;
  grid-column-end: 11.5;
}

.col-span-10 {
  grid-column: span 10;
}

.row-span-10 {
  grid-row: span 10;
}

.col-center-10 {
  grid-column: 2 / 12;
}

.col-span-11 {
  grid-column: span 11;
}

.row-span-11 {
  grid-row: span 11;
}

.col-center-11 {
  grid-column-start: 1.5;
  grid-column-end: 12.5;
}

.col-span-12 {
  grid-column: span 12;
}

.row-span-12 {
  grid-row: span 12;
}

.col-center-12 {
  grid-column: 1 / 13;
}

@keyframes jump {
  from {
    transform: translate(0);
  }

  25% {
    transform: translate(0, -6px);
  }

  50% {
    transform: translate(0);
  }
}

.jumping-ellipsis {
  padding-left: .25rem;
  display: inline-block;
}

.jumping-ellipsis > div {
  animation: jump 1.2s infinite;
  display: inline-block;
}

.jumping-ellipsis > div:nth-child(1) {
  animation-delay: .125s;
}

.jumping-ellipsis > div:nth-child(2) {
  animation-delay: .25s;
}

.jumping-ellipsis > div:nth-child(3) {
  animation-delay: .375s;
}

:root {
  --sentiment-start: #eb6e6e;
  --sentiment-end: #c980e1;
  --sentiment-speed: 4s;
}

.sentiment-header {
  animation-name: sentiment-breathing;
  animation-direction: alternate;
  animation-duration: var(--sentiment-speed);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@media print {
  .grid {
    display: block;
  }

  .grid > .card {
    margin-bottom: 2rem;
  }

  .sentiment-header {
    background-image: linear-gradient(to right, #833ab4, #fd1d1d);
  }
}

@keyframes sentiment-breathing {
  0% {
    background: var(--sentiment-start);
  }

  50% {
    background: var(--sentiment-end);
  }

  100% {
    background: var(--sentiment-start);
  }
}

.collapsible-list, .arrow.asc, .arrow.desc {
  position: relative;
}

.arrow.asc:after, .arrow.desc:after {
  content: "";
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
}

.collapsible-list:before {
  content: "";
  width: 0;
  height: 0;
  margin-top: 6px;
  margin-left: -20px;
  position: absolute;
}

.collapsible-list.closed:before {
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #000;
  margin-top: 4px;
  margin-left: -15px;
}

.collapsible-list.opened:before, .arrow.asc:after {
  border-top: 5px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
}

.arrow.desc:after {
  border-bottom: 5px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
}

.djs-group {
  user-select: none;
}

.djs-group .user {
  cursor: pointer;
}

.djs-group .user.hover .djs-visual > rect {
  fill: #c8c8c866 !important;
}

.djs-group .user.selected .djs-visual > rect {
  stroke: #ba2e6d !important;
  fill: #ba2e6d33 !important;
}

.layer-base > .djs-group > :not(.user) {
  opacity: .3;
}

.absolute-spinner {
  z-index: 9997;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-view.edit table > tr:after {
  content: "edit";
  font-size: 1.5rem;
  position: relative;
  top: 6px;
}

.table-view tr.no-edit:after {
  width: 1.5rem;
  content: "" !important;
}

.table-view table {
  width: 100%;
}

.table-view table > tr {
  min-height: 42px;
  border-bottom: 1px solid #d3d3d3;
  gap: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
}

.table-view table > tr:first-child, .table-view table > tr:last-child {
  border-bottom: none;
}

.table-view table > tr:hover:not(:first-child) {
  color: #fff;
  cursor: pointer;
  background-color: #ba2e6d;
}

.table-view table > tr:first-child:after {
  opacity: 0;
}

.table-view table > tr * {
  flex: 1;
}

.table-view table > tr th:first-child {
  text-align: left;
}

.table-view table > tr td:not(:first-child) {
  text-align: center;
}

.table-view table > tr td, .table-view table > tr th {
  align-items: center;
  display: flex;
}

.table-view table > tr td > div, .table-view table > tr th > div {
  margin: auto;
}

.table-view .table-active-indicator {
  width: 10px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  margin: 10px;
  display: block;
  position: relative;
  transform: scale(1);
}

.table-view .table-active-indicator.pulse {
  background: #33d9b2;
  transition: all .2s;
  animation: pulse-green 2s infinite;
  box-shadow: 0 0 #33d9b2;
}

@keyframes pulse-green {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #33d9b2b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #33d9b200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #33d9b200;
  }
}

.flex-start {
  align-items: flex-start;
}

html.save-as * {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  pointer-events: none;
}

html.save-as .noprint, html.save-as .daterange span, html.save-as .tooltip.kpi-label {
  display: none;
}

html.save-as .type-analyses .navigation {
  display: none !important;
}

html.save-as .type-analyses .analyses-accordion .analyses {
  display: initial;
  height: auto;
  flex-direction: column;
}

html.save-as .type-analyses .analysis {
  widows: 2;
  orphans: 2;
  padding: 1rem 0;
  flex-basis: unset !important;
  break-inside: avoid !important;
}

html.save-as .type-open_question .table-graph .graphgrid {
  max-height: unset;
}

html.save-as .type-open_question .table-graph .graphgrid .row .column.date {
  opacity: 1;
}

html.save-as .type-choice_question .flex {
  flex-direction: column;
}

html.save-as .type-choice_question .flex > div {
  flex-shrink: 1;
  padding: 0 1rem 0 3rem;
}

html.save-as .type-choice_question .flex > div:not(:last-child) {
  margin-bottom: 2rem;
}

html.save-as .noprint, html.save-as .expand {
  display: none !important;
}

html.save-as .card-grid .col-center-8 {
  grid-column: 1 / 12;
}

html.save-as .pe-icon.pe-icon--regular.dropdown, html.save-as .card-grid form, html.save-as .answer-filter, html.save-as .daterange .pe-button:not(.pe-button--selected) {
  display: none;
}

html.save-as .card {
  filter: none;
  border: 2px solid #eee;
}

html.save-as .card.type-image img {
  width: 100%;
}

html.save-as .card .card-grid {
  display: initial;
}

html.save-as .card .card-info, html.save-as .card .card-tabs, html.save-as .card .card-options-icon, html.save-as .card .card-options-menu, html.save-as .card .insight-feedback-wrapper {
  display: none !important;
}

html.save-as .labels, html.save-as .overlays, html.save-as .responses-labels, html.save-as .answer-labels {
  opacity: 1 !important;
}

html.save-as .graphcontainer .overlays, html.save-as .graphcontainer .labels {
  font-size: 16px;
}

html.save-as .graphcontainer .tooltip {
  display: none;
}

html.save-as .graphcontainer svg .axis, html.save-as .graphcontainer svg .label, html.save-as .graphcontainer svg .labels {
  color: #555;
  fill: #555;
}

.card.save-as {
  filter: none;
  pointer-events: none;
  border: 2px solid #eee;
}

.card.save-as .noprint, .card.save-as .card-options-icon, .card.save-as .tooltip.kpi-label, .card.save-as .insight-feedback-wrapper, .card.save-as .card-info, .card.save-as .card-tabs, .card.save-as .card-options-icon, .card.save-as .card-options-menu {
  display: none !important;
}

.card.save-as.type-image img {
  width: 100%;
}

.card.save-as .card-grid {
  display: initial;
}

.card.save-as .labels, .card.save-as .overlays, .card.save-as .responses-labels, .card.save-as .answer-labels {
  opacity: 1 !important;
}

.card.save-as .graphcontainer .overlays, .card.save-as .graphcontainer .labels {
  font-size: 16px;
}

.card.save-as .graphcontainer .tooltip {
  display: none;
}

.card.save-as .graphcontainer svg .axis, .card.save-as .graphcontainer svg .label, .card.save-as .graphcontainer svg .labels {
  color: #555;
  fill: #555;
}

.type-analyses .card.save-as .navigation {
  display: none !important;
}

.type-analyses .card.save-as .analyses-accordion .analyses {
  display: initial;
  height: auto;
  flex-direction: column;
}

.type-analyses .card.save-as .analysis {
  widows: 2;
  orphans: 2;
  padding: 1rem 0;
  flex-basis: unset !important;
  break-inside: avoid !important;
}

.type-smart_summary .card.save-as .entries {
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  display: grid;
}

.type-smart_summary .card.save-as .entries .entry {
  margin-right: 0 !important;
}

.type-open_question .card.save-as .table-graph .graphgrid {
  max-height: unset;
}

.type-open_question .card.save-as .table-graph .graphgrid .row .column.date {
  opacity: 1;
}

.type-choice_question .card.save-as .flex {
  flex-direction: column;
}

.type-choice_question .card.save-as .flex > div {
  flex-shrink: 1;
  padding: 0 1rem 0 3rem;
}

.type-choice_question .card.save-as .flex > div:not(:last-child) {
  margin-bottom: 2rem;
}

.type-priorities .card.save-as .prioritymatrix {
  flex-wrap: nowrap;
}

.type-priorities .card.save-as .prioritymatrix svg .quadrants text {
  font-size: 1.5rem;
}

.type-priorities .card.save-as .prioritymatrix .prioritymatrix-legend .values {
  margin: 0;
}

.type-labels_score .card.save-as .label-score-body {
  max-height: none;
}

.type-labels_score .card.save-as .label-score-body .score, .type-labels_score .card.save-as .label-score-body .label__start, .type-labels_score .card.save-as .label-score-body .label__end, .type-labels_score .card.save-as .label-score-body .label__score-container .label__score, .type-labels_score .card.save-as .label-score-body .label__required-value-container .label__required-value, .type-stacked_comparison .card.save-as svg .text {
  opacity: 1;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  background: #fbf8fd;
}

#body {
  margin-top: -1px;
  padding-top: 1px;
  display: flex;
}

#body > * {
  flex-basis: 100%;
}

a {
  text-decoration: none;
}

hr {
  border: 1px solid #ccc;
}

p.f-inherit, a.f-inherit, i.f-inherit, b.f-inherit, strong.f-inherit, h1.f-inherit, h2.f-inherit, h3.f-inherit, h4.f-inherit, h5.f-inherit, h6.f-inherit {
  font-size: inherit;
  line-height: inherit;
}

.z--1 {
  z-index: -1;
}

.flex-1 {
  flex: 1;
}

.pre-wrap {
  white-space: pre-wrap;
}

.v-fill-screen {
  min-height: calc(100vh - var(--header-height));
}

.measure-narrow, .measure, .measure-wide, .measure-broad {
  margin-left: auto;
  margin-right: auto;
}

.measure-broad {
  width: 95%;
  max-width: 96rem !important;
}

.page {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.nested-svg > svg {
  width: 100%;
  max-width: 100%;
  display: block;
}

.skeleton .skel {
  background-image: linear-gradient(90deg, #d2d2d2 80%, #f0f0f0 90%, #d2d2d2 100%);
  background-repeat: repeat;
  background-size: 200% 100%;
  animation: slide 1s infinite;
}

.skeleton:nth-child(3n+1) .skel, .skeleton .skel:nth-child(3n+1) {
  animation-delay: .1s;
}

.skeleton:nth-child(3n+2) .skel, .skeleton .skel:nth-child(3n+2) {
  animation-delay: 60ms;
}

.skeleton:nth-child(3n+3) .skel, .skeleton .skel:nth-child(3n+3) {
  animation-delay: 20ms;
}

i.before {
  vertical-align: top;
  margin-right: .5rem;
  font-size: 1rem;
}

.no-select {
  user-select: none;
}

.capitalize {
  text-transform: capitalize;
}

.noshadow {
  box-shadow: none !important;
}

.small-action {
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  color: #ba2e6d;
  background: #fff;
  border-radius: 100%;
  padding: 0;
  transition: all .15s;
  box-shadow: 0 4px 6px #3f040c33;
}

.small-action:hover, .small-action.active {
  color: #fff;
  background: #ba2e6d;
}

.small-action:active {
  box-shadow: inset 0 4px 6px #3f040c33;
}

.small-action i {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.pointer-events-none {
  pointer-events: none;
}

.fade-in {
  animation: fadeIn .5s;
}

.tsparticles canvas {
  border-radius: .5rem;
  overflow: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 70em) {
  .sticky-top {
    z-index: 1;
    filter: drop-shadow(0 .3rem .15rem #0000000d);
    border: none;
    padding: .5rem 1.5rem;
    position: sticky;
    top: calc(4rem - 12px);
  }
}

@media print {
  body, html {
    background-color: #fff;
    font-size: 12px;
    color: #000 !important;
  }

  #body {
    display: block;
  }

  .noprint {
    display: none;
  }

  table, tr, td, th, tbody, thead, tfoot {
    page-break-inside: avoid !important;
  }

  .daterange span {
    display: none;
  }

  section:not(:first-child) {
    page-break-before: always;
    break-before: always;
  }

  .titleseparator {
    page-break-after: avoid;
    break-after: avoid;
    margin-bottom: 2rem !important;
  }

  .titleseparator .spacing {
    display: none !important;
  }

  .titleseparator hr {
    display: none;
  }

  svg {
    height: auto !important;
  }

  .grid > div:not(.type-analyses):not(.type-open_question) {
    break-inside: avoid;
  }

  .tooltip.kpi-label {
    display: none;
  }

  .type-analyses .navigation {
    display: none !important;
  }

  .type-analyses .analyses-accordion .analyses {
    display: initial;
    height: auto;
    flex-direction: column;
  }

  .type-analyses .analyses-accordion .analyses .analysis {
    widows: 2;
    orphans: 2;
    padding: 1rem 0;
    flex-basis: unset !important;
    break-inside: avoid !important;
  }

  .type-open_question .table-graph .graphgrid {
    max-height: unset;
  }

  .type-open_question .table-graph .graphgrid .row .column.date {
    opacity: 1;
  }

  .type-choice_question .flex {
    flex-direction: column;
  }

  .type-choice_question .flex > div {
    flex-shrink: 1;
    padding: 0 1rem 0 3rem;
  }

  .type-choice_question .flex > div:not(:last-child) {
    margin-bottom: 2rem;
  }

  .noprint, .expand {
    display: none !important;
  }

  .card-grid .col-center-8 {
    grid-column: 1 / 12;
  }

  .pe-icon.pe-icon--regular.dropdown, .card-grid form, .answer-filter, .daterange .pe-button:not(.pe-button--selected) {
    display: none;
  }

  .card {
    filter: none;
    border: 2px solid #eee;
  }

  .card.type-image img {
    width: 100%;
  }

  .card {
    box-decoration-break: clone;
  }

  .card .card-info {
    display: none;
  }

  .card .card-grid {
    display: initial;
  }

  .card .card-grid .card-tabs {
    display: none;
  }

  .labels, .overlays, .responses-labels, .answer-labels {
    opacity: 1 !important;
  }

  .graphcontainer .overlays, .graphcontainer .labels {
    font-size: 16px;
  }

  .graphcontainer .tooltip {
    display: none;
  }

  @page {
    margin: 2rem 0;
  }

  @page :first {
    margin: 0 0 2rem;
  }
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@keyframes slide {
  0% {
    background-position-x: 0%;
  }

  100% {
    background-position-x: -133%;
  }
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

#modal {
  z-index: 2147483647;
  background: #0009;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

#modal .content {
  max-height: calc(100% - 5rem);
  color: #1a1a1a;
  max-width: 32rem;
  background: #fbf8fd;
  border-radius: 1rem;
  flex-direction: column;
  margin: auto;
  display: flex;
  position: relative;
}

#modal .content .header {
  border-bottom: 1px solid #d3d3d3;
  padding: 1.25rem 1.5rem;
}

#modal .content .header h2 {
  margin: 0;
  font-size: 1.25rem;
}

#modal .content .header .close_btn {
  width: 1rem;
  height: 1rem;
  text-align: center;
  border-radius: 100%;
  padding: .5rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

#modal .content .body {
  border-bottom: 1px solid #d3d3d3;
  padding: 2rem 1.5rem;
}

#modal .content .footer {
  border: none;
  padding: .5rem 1.5rem;
  line-height: 1rem;
}

#modal .content .footer .close_btn {
  border-radius: .25rem;
  margin-right: -1rem;
  padding: .5rem .875rem;
}

#modal .content .close_btn {
  text-transform: uppercase;
  cursor: pointer;
  float: right;
  color: #ba2e6d;
  font-size: .875rem;
  font-weight: bold;
  transition: background .1s ease-in;
}

#modal .content .close_btn:hover {
  background: #ba2e6d0d;
}

.pe-dialog-pane .pe-dialog-pane__content {
  overflow-x: hidden;
  overflow-y: auto;
}

.pe-dialog-pane .pe-dialog-pane__body {
  overflow-y: inherit;
  overflow-x: inherit;
}

/*# sourceMappingURL=index.930bc4b7.css.map */
