$light-grey: #eeeeee
$light-text: #aaaaaa

$satys-dark-accent: #3f040c
$satys-accent: #ba2e6d
$satys-light-accent: #fbf8fd

$satys-dark-blue: #001b44

$satys-purple: #833AB4
$satys-red: #FD1D1D
$satys-light-purple: #C980E1
$satys-light-red: #EB6E6E
$satys-light-blue: #ADD1EB
$satys-light-green: #33D9B2

$satys-graph-red: #AE7F7F
$satys-graph-green: #84AD7F
$satys-graph-blue: #93D9F1
$satys-graph-neutral: #DEDEDE

$satys-text-color: #1B1249
$satys-text-color-light: #8B97A9
$satys-bg-color: #F9F9FB


$transition: ease-in-out 250ms

@mixin small
    @media (max-width: 30em)
        @content
@mixin not-large
    @media (max-width: 70em)
        @content

@mixin not-small
    @media (min-width: 30em)
        @content
@mixin medium
    @media (min-width: 30em)
        @content
@mixin large
    @media (min-width: 70em)
        @content

:root
    --light-grey: #{$light-grey}
    --satys-dark-accent: #{$satys-dark-accent}
    --satys-accent: #{$satys-accent}
    --satys-light-accent: #{$satys-light-accent}

    --satys-dark-blue: #{$satys-dark-blue}

    --satys-purple: #{$satys-purple}
    --satys-red: #{$satys-red}
    --satys-light-purple: #{$satys-light-purple}
    --satys-light-red: #{$satys-light-red}
    --satys-light-blue: #{$satys-light-blue}
    --satys-light-green: #{$satys-light-green}

    --satys-graph-red: #{$satys-graph-red}
    --satys-graph-green: #{$satys-graph-green}
    --satys-graph-blue: #{$satys-graph-blue}
    --satys-graph-neutral: #{$satys-graph-neutral}

    --satys-text-color: #{$satys-text-color}
    --satys-text-color-light: #{$satys-text-color-light}

    --satys-bg-color: #{$satys-bg-color}

    --header-height: 0px

    --animation-duration: 500
    --animation-duration-long: 1000
